
import { defineComponent, ref } from 'vue'

export default defineComponent({
    props: {
        textlabel: { type: String },
        value: { type: String },
        editable: { type: Boolean, default: false },
    },
    setup(props, { emit }) {
        const tooltip = ref(false)
        const copyMessage = () => {
            const el = document.createElement('textarea')
            el.value = props.value ?? ''
            el.setAttribute('readonly', '')
            el.style.position = 'absolute'
            el.style.left = '-9999px'
            document.body.appendChild(el)
            el.select()
            document.execCommand('copy')
            document.body.removeChild(el)
        }
        const copy = () => {
            copyMessage()
            setTimeout(() => {
                tooltip.value = false
            }, 1000)
        }

        return { props, emit, copy, tooltip }
    },
})
