import { defineStore } from 'pinia'

import _ from 'lodash'
import store from '..'

export const useHintStore = defineStore('hint', {
    state: () => ({}),
    actions: {
        openHintSidebar(hintId: string) {
            store.commit('setIsHintSidebar', true)
            store.commit('setSidebarHintId', hintId)
        },
    },
    getters: {},
})
